import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import quote from "./quote.png";
import founderimg from "./founder-image.png";
import founderimg2 from  './sohan-tusir.png';
const Founder = () => {
  const options = {
    loop: true,
    nav:false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 4000,
    items: 1
  };

  return (
    <section id="founder-message">
      <div className="container-fluid">
        <div className="row">
          {/* Title Section */}
          <div className="col-md-3 col-12 align-self-center">
            <div className="founder-left">
              <h4>Our Founder’s Message</h4>
              <div className="founderline"></div>
            </div>
          </div>

          {/* Founder Image Section */}
          <div className="col-md-3 col-12 bg-blue1 align-content-end">
            <div className="founder-image">
              <img src={quote} className="img-fluid quote" alt="Quote" />
               
                <div className="item">
                  <img src={founderimg2} className="img-fluid founderimg" alt="Sohan Tusir" />
                </div>
            </div>
          </div>

          {/* Founder Message Section */}
          <div className="col-md-6 col-12">
            <div className="founder-caption">
                
                <div className="item">
                  <div className="founderheading">
                    <h4>SOHAN TUSIR</h4>
                    <h6>CEO</h6>
                  </div>
                  <p>
                    Sohan Tusir, a former national kabaddi player, is a visionary leader committed
                    to transforming kabaddi into a global phenomenon. As the CEO of IPKL, he
                    leverages his deep experience and passion for the sport to elevate kabaddi's
                    profile worldwide.
                  </p>
                  <p>
                    Under his leadership, IPKL is set to become the premier stage for kabaddi
                    athletes, showcasing the sport’s vibrancy on both national and international
                    platforms.
                  </p>
                </div>

              {/* Bottom Arrow */}
              <div className="carousel-arrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Founder;
